import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const Container = ({ children, colour, edges }) => {
  if (!colour) {
    colour = "white";
  }

  if (typeof edges === "undefined") {
    edges = false;
  }

  // if (edges) {
  //   return (
  //     <div className="flex flex-row md:max-w-2xl lg:max-w-4xl container mx-auto">
  //       <div className="">
  //         <StaticImage
  //           src="../images/ragged-edge-01.svg"
  //           width="100%"
  //           height="100%"
  //         />
  //       </div>
  //       <div className="p-4 bg-white">{children}</div>
  //       <div className="">
  //         <StaticImage
  //           src="../images/ragged-edge-01.svg"
  //           width="100%"
  //           height="100%"
  //           className="rotate-180"
  //         />
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div
      className={
        "bg-" + colour + " md:max-w-2xl lg:max-w-4xl container mx-auto p-4 my-4"
      }
    >
      {children}
    </div>
  );
};

export default Container;
