import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import Container from "./Container";

const componentQuery = graphql`
  query Data {
    allMarkdownRemark(
      sort: { fields: frontmatter___headerOrder }
      filter: { frontmatter: { headerOrder: { gt: 0 } } }
    ) {
      nodes {
        frontmatter {
          slug
          title
          headerOrder
        }
      }
    }
  }
`;

const MenuItem = ({ path, title }) => (
  <Link className="p-2 hover:bg-gray-300" to={path}>
    {title}
  </Link>
);

export const HeaderMenu = () => {
  const data = useStaticQuery(componentQuery)?.allMarkdownRemark?.nodes?.map(
    (n) => n?.frontmatter ?? n
  );

  return (
    <Container colour="gray-500">
      <nav>
        <MenuItem path="/" title="Home" />
        {data.map((n, i) => (
          <MenuItem path={n.slug} title={n.title} key={i} />
        ))}
      </nav>
    </Container>
  );
};

export default HeaderMenu;
