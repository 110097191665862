import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Container from "../components/Container";
import HeaderMenu from "../components/HeaderMenu";
import "../styles/index.css";

export const Layout = ({ children }) => (
  <>
    <Container colour="none">
      <Link to="/">
        <StaticImage src="../images/ventact-horizontal-with-tagline.svg" />
      </Link>
    </Container>
    <HeaderMenu />
    {children}
    <Container colour="gray-500 mt-16">foter</Container>
  </>
);

export default Layout;
